export const colors = {
  background: '#F5F5F5',
  white: 'white',

  primary: 'black',
  secondary: '#686868',

  grey: '#7B7B7B',
  lightGrey: '#D8D8D8',

  red: '#FF4848',
  purple: '#07068E',

  tags: {
    bikini: '#49CDF2',
    other: '#F74972',
    festival: '#FFEE59',
  },
}

export const MOBILE_BREAKPOINT = 800
