import {useEffect, useState} from 'react'

export const useClient = () => {
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])
  return isClient
}

export const useClientKey = () => {
  const isClient = useClient()
  return (id: string) => `${isClient ? '' : '@'}${id}`
}
