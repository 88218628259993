import {BikiniBanner} from '../types/bikini'

import React, {useState, useEffect, CSSProperties} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

import Image from './image'
import Link from './link'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {currentTimestamp} from '../helpers/date'
import {intersec} from '../helpers/array'
import {isBrowser} from '../helpers/window'
import {useClientKey} from '../helpers/hooks'

const RATIO = 12.5 // % height/width of the banners in Packweb

const Section = styled.section`
  padding-bottom: ${RATIO}%; /* because it's proportional to the width */
  height: 0;
  overflow: hidden;
  background: ${colors.white};
  margin: 0 0 25px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 -10px 10px;
  }
  a {
    display: block;
  }
  .gatsby-image-wrapper div {
    /* fix images that are don't exactly have the right RATIO (TODO: can be removed once all images are 1000x250) */
    padding-bottom: ${RATIO}% !important;
  }
`

interface Props {
  categories?: number[]
}

const Banner: React.FC<Props> = ({categories}) => {
  const {
    allBikiniBanner: {edges},
  } = useStaticQuery(graphql`
    query {
      allBikiniBanner {
        edges {
          node {
            id
            start
            end
            exclusive
            order
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            url
            name
            duration
            newTab
            categories
          }
        }
      }
    }
  `)

  const now = currentTimestamp()
  let banners: BikiniBanner[] = edges.map(({node}) => node).filter(({start, end}) => start < now && end > now)

  const style: CSSProperties = {}

  const exclusiveBanners = banners.filter(({exclusive}) => exclusive)
  if (exclusiveBanners.length > 0) {
    banners = exclusiveBanners
  } else {
    if (categories) {
      banners = banners.filter((banner) => intersec(banner.categories, categories).length > 0)
    }
  }

  if (!banners.length) {
    return null
  }

  banners.sort((left: BikiniBanner, right: BikiniBanner) => {
    // sort by order and then by end date, having the soonest ones first:
    if (left.order === right.order) {
      return left.end > right.end ? 1 : -1
    }
    return left.order > right.order ? 2 : -2
  })

  const getInitialIndex = () => {
    if (!isBrowser()) {
      return 0
    }
    try {
      const stored = localStorage.getItem('banners')
      if (!stored) {
        return 0
      }
      let value = 0
      const ids = stored.split(',')
      banners.forEach(({id}, idx) => {
        if (ids.includes(id)) {
          value = idx // last included
        }
      })
      return value
    } catch (e) {
      return 0
    }
  }

  const [index, setIndex] = useState(getInitialIndex)

  const next = () => {
    setIndex(index === banners.length - 1 ? 0 : index + 1)
  }

  useEffect(() => {
    try {
      localStorage.setItem(
        'banners',
        banners
          .slice(0, index + 1)
          .map(({id}) => id)
          .join(',')
      )
    } catch (e) {
      // ignore
    }

    const timeout = setTimeout(next, (banners[index] || banners[0]).duration * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [index, banners.length])

  style.marginTop = `-${index * RATIO}%`
  if (index > 0) {
    style.transition = 'margin-top 0.3s'
  }

  const key = useClientKey()

  return (
    <Section>
      <div style={style}>
        {banners.slice(0, index + 2).map(({id, image, url, name, newTab}: BikiniBanner) => (
          <Link key={key(id)} url={url} target={newTab ? '_blank' : null} aria-label={name}>
            <Image src={image} alt={name} loading="eager" />
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default Banner
