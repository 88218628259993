import {BikiniEvent} from '../types/bikini'

import React, {useState, useEffect, useRef} from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled, {css} from 'styled-components'

import Image from '../components/image'
import Menu from './menu'
import MenuIcon from '../images/menu'
import Logo from '../images/logo'
import SearchIcon from '../images/search'
import Facebook from '../images/facebook'
import Instagram from '../images/instagram'

import {upcoming, timeFormat, eventFilter} from '../helpers/date'
import {ellipsis, available} from '../helpers/text'
import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {getScroll, isBrowser} from '../helpers/window'
import {clamp, socialButton} from '../helpers/style'
import {useClientKey} from '../helpers/hooks'
import BookButton from '../components/bookbutton'

const STRIP_HEIGHT = 41
const NAV_HEIGHT = 127
export const DESKTOP_HEIGHT = 63
export const MOBILE_HEIGHT = 50
export const getHeight = () => (window.innerWidth > MOBILE_BREAKPOINT ? DESKTOP_HEIGHT : MOBILE_HEIGHT)

const Container = styled.header`
  height: ${STRIP_HEIGHT + NAV_HEIGHT}px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${MOBILE_HEIGHT}px;
  }
`

const Strip = styled.div`
  background-color: ${colors.primary};
  color: ${colors.white};
  height: ${STRIP_HEIGHT}px;
  padding: 0 30px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`
const linkStyle = css`
  font-size: 0.7rem;
  padding: 14px;
  color: ${colors.white};
  display: inline-block;
`
const InternalLink = styled(Link)`
  ${linkStyle}
  font-weight: 600;
  margin-right: 15px;
`
const ExternalLink = styled.a`
  ${linkStyle}
  font-weight: 600;
  margin-right: 15px;
`
const FindUs = styled.span`
  font-weight: 600;
  ${linkStyle}
`
const SocialLink = styled.a`
  ${linkStyle}
  font-weight: normal;
  svg {
    width: 12px;
    height: 12px;
    fill: ${colors.white};
    margin-right: 2px;
    vertical-align: -2px;
  }
`

const Nav = styled.nav<{fixed: boolean}>`
  position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  right: 0;
  height: ${NAV_HEIGHT}px;
  z-index: 5;
  background: ${colors.white};
  display: flex;
  align-items: center;
  overflow: hidden;
  > * {
    font-size: 0.9rem;
    font-weight: 600;
    vertical-align: middle;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
    display: block;
    text-align: center;
    height: ${MOBILE_HEIGHT}px;
  }
`
const MenuButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  > * {
    vertical-align: middle;
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`
const MenuText = styled.span`
  font-size: 1rem;
  padding: 5px 10px;
`
const LogoLink = styled(Link)`
  display: inline-block;
  width: 150px;
  padding: 0 25px;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80px;
    height: 100%;
  }
`
const Separator = styled.div`
  width: 3px;
  background: ${colors.background};
  margin: 0 20px;
  height: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`
const NavLinks = styled.div`
  flex: 1;
  white-space: nowrap;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`
const NavLink = styled(Link)`
  display: inline-block;
  padding: 10px;
  color: ${colors.primary};
  text-transform: uppercase;
  font-size: 0.85rem;
`

const NextEvent = styled(Link)<{hidden: boolean}>`
  position: absolute;
  z-index: 6;
  top: 12px;
  transition: transform 0.2s, opacity 0.2s;
  transform: translateY(${(props) => (props.hidden ? -120 : 0)}px);
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  right: 190px;
  display: flex;
  align-items: flex-end;
  @media (max-width: 1220px) {
    display: none;
  }
`
const EventDescription = styled.div`
  text-align: right;
  padding: 0 5px;
  margin: 0 11px -4px 0;
  line-height: 1.3rem;
  color: ${colors.primary};
  max-width: 150px;
  font-size: 0.85rem;
`
const EventText = styled.div`
  text-transform: uppercase;
`
const EventTitle = styled.div`
  font-weight: bold;
  word-wrap: break-word;
  ${clamp(2)}
`

const BLOCK_IMAGE = {display: 'block'} // needed to override inline gatsby image wrapper styling

const EventLabel = styled(BookButton)<{available: boolean}>`
  display: block;
  text-align: center;
  color: ${colors.primary};
  padding: 6px 0 5px;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  background: ${(props) => (props.available ? colors.background : colors.red)};
`

const Social = styled.div<{visible: boolean}>`
  transition: transform 0.2s, opacity 0.2s;
  transform: translateY(${(props) => (props.visible ? 0 : 100)}px);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  @media (max-width: 1100px) {
    display: none;
  }
`
const SocialButton = styled.a`
  text-align: center;
  ${socialButton()}
`

const SearchButton = styled(Link)`
  color: ${colors.primary};
  font-size: 1rem;
  font-weight: 600;
  margin: 0 25px 3px;
  svg {
    vertical-align: -12px;
    width: 35px;
    height: 35px;
  }
  @media (max-width: 900px) {
    span {
      display: none;
    }
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    margin: 0;
    padding: 0 7px 0 20px;
    line-height: ${MOBILE_HEIGHT}px;
    svg {
      vertical-align: middle;
      width: 24px;
      height: 24px;
    }
  }
`

const getFixedHeight = () => {
  const values = {
    height: null,
    fixed: false,
  }
  if (isBrowser()) {
    const scroll = getScroll()
    if (window.innerWidth > MOBILE_BREAKPOINT) {
      values.height = Math.min(NAV_HEIGHT, Math.max(DESKTOP_HEIGHT, NAV_HEIGHT - scroll + STRIP_HEIGHT))
      values.fixed = scroll > STRIP_HEIGHT
    } else {
      values.fixed = true
    }
  }
  return values
}

const Header: React.FC = () => {
  const [showMenu, setMenu] = useState(false)
  const [scroll, setScroll] = useState<{height?: number; fixed?: boolean}>({})
  const ref = useRef<{height?: number; fixed?: boolean}>({})

  useEffect(() => {
    const handleScroll = () => {
      const values = getFixedHeight()
      if (ref.current.height !== values.height || ref.current.fixed !== values.fixed) {
        ref.current = values
        setScroll(values)
      }
    }

    document.addEventListener('scroll', handleScroll, {passive: true})
    window.addEventListener('resize', handleScroll)
    handleScroll()
    return () => {
      document.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
      document.body.classList.remove('modal')
    }
  }, [])

  const handleMenu = () => {
    setMenu(!showMenu)
    document.body.classList[showMenu ? 'remove' : 'add']('modal')
  }

  const {
    site: {siteMetadata: data},
    allBikiniEvent: {edges},
    allBikiniSection,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          facebook
          instagram
        }
      }
      allBikiniEvent(
        filter: {past: {eq: false}, featured: {eq: true}, status: {nin: ["cancelled", "postponed", "full"]}}
        sort: {fields: [date], order: ASC}
      ) {
        edges {
          node {
            header
            path
            date
            end
            unveilDate
            title
            shortTitle
            status
            ticketUrl
            password
            passwordLimitDate
            passwordPrompt
            free
            image {
              childImageSharp {
                fixed(width: 120, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      allBikiniSection(filter: {slug: {eq: "playlist"}}) {
        edges {
          node {
            content
          }
        }
      }
    }
  `)

  const events = edges.map(({node}) => node)

  let spotifyId: string
  allBikiniSection.edges.forEach(({node}) => {
    const matches = node.content.match(/\/playlist\/([^/\\"]+)/)
    if (matches) {
      spotifyId = matches[1]
    }
  })

  // TODO: live unveil:
  const nextEvent = events.find((event: BikiniEvent) => eventFilter(event) && event.header) || events.find(eventFilter)
  const key = useClientKey()

  return (
    <Container>
      <Strip>
        <InternalLink to="/infos/#newsletter">S'abonner à la newsletter</InternalLink>
        {spotifyId && (
          <ExternalLink href={`https://open.spotify.com/playlist/${spotifyId}`} target="_blank" rel="noopener">
            Découvrir la playlist du Bikini
          </ExternalLink>
        )}
        <FindUs>Retrouvez-nous :</FindUs>
        <SocialLink href={`https://www.facebook.com/${data.facebook}`} target="_blank" rel="noopener">
          <Facebook /> Facebook
        </SocialLink>
        <SocialLink href={`https://www.instagram.com/${data.instagram}/`} target="_blank" rel="noopener">
          <Instagram /> Instagram
        </SocialLink>
        <NextEvent to={nextEvent.path} title={nextEvent.title} hidden={scroll.fixed} key={key(nextEvent.id)}>
          <EventDescription>
            <EventText>{upcoming(nextEvent.date, nextEvent.end)}</EventText>
            <EventTitle>{nextEvent.shortTitle || ellipsis(nextEvent.title, 40)}</EventTitle>
            <EventText>{timeFormat(nextEvent.date)}</EventText>
          </EventDescription>
          <div>
            <Image src={nextEvent.image} style={BLOCK_IMAGE} backgroundColor={colors.background} />
            <EventLabel event={nextEvent} available={available(nextEvent)} />
          </div>
        </NextEvent>
      </Strip>
      <Nav fixed={scroll.fixed} style={{height: scroll.height}}>
        <MenuButton onClick={handleMenu}>
          <MenuIcon />
          <MenuText>Menu</MenuText>
        </MenuButton>
        <LogoLink to="/" aria-label="Logo">
          <Logo />
        </LogoLink>
        <Separator />
        <NavLinks>
          <NavLink to="/programmation/">Programmation</NavLink>
          <NavLink to="/billetterie/">Billetterie</NavLink>
          <NavLink to="/infos/">Infos pratiques</NavLink>
          <NavLink to="/adherez/">Adhérez</NavLink>
        </NavLinks>
        <Social visible={scroll.fixed}>
          <SocialButton
            href={`https://www.facebook.com/${data.facebook}`}
            title="Facebook"
            target="_blank"
            rel="noopener"
          >
            <Facebook />
          </SocialButton>
          <SocialButton
            href={`https://www.instagram.com/${data.instagram}/`}
            title="Instagram"
            target="_blank"
            rel="noopener"
          >
            <Instagram />
          </SocialButton>
        </Social>
        <SearchButton to="/search/" aria-label="Rechercher">
          <span>recherche </span>
          <SearchIcon />
        </SearchButton>
      </Nav>
      <Menu open={showMenu} onClose={handleMenu} />
    </Container>
  )
}

export default Header
