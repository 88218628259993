import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'

import CloseIcon from '../images/close'
import Facebook from '../images/facebook'
import Instagram from '../images/instagram'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'

const CloseButton = styled.button`
  margin: -10px;
  padding: 15px 10px;
  span {
    color: ${colors.white};
    font-size: 1rem;
    margin-left: 15px;
  }
  svg {
    width: 16px;
    height: 16px;
    vertical-align: -2px;
  }
`

const Container = styled.div<{open: boolean}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: ${colors.grey};
  padding: 20px;
  transition: transform 0.3s;
  transform: translateX(${(props) => (props.open ? 0 : -100)}vw);
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px;
  }
`
const Nav = styled.div`
  margin-top: 12vh; /* original design was 14vh */
  margin-right: 15vw;
  padding-top: 2vh;
  border-top: 2px solid ${colors.white};
`
const NavLink = styled(Link)`
  display: block;
  padding: 2.7vh 0;
  div {
    height: 25px;
    color: ${colors.white};
    font-size: 1.85rem;
    overflow: hidden;
    font-weight: 600;
  }
`

const Social = styled.div`
  margin-top: 4vh;
`
// TODO: reuse style from <Header>:
const SocialButton = styled.a`
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 31px;
  margin-right: 30px;
  border-radius: 20px;
  border: 1px solid ${colors.white};
  text-align: center;
  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    fill: ${colors.white};
  }
`

const Menu = ({open, onClose}) => {
  const {
    site: {siteMetadata: data},
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          facebook
          instagram
          twitter
        }
      }
    }
  `)

  return (
    <Container open={open}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
        <span>Fermer</span>
      </CloseButton>
      <Nav>
        <NavLink to="/programmation/" onClick={onClose}>
          <div>Programmation</div>
        </NavLink>
        <NavLink to="/billetterie/" onClick={onClose}>
          <div>Billetterie</div>
        </NavLink>
        <NavLink to="/infos/" onClick={onClose}>
          <div>Infos pratiques</div>
        </NavLink>
        <NavLink to="/adherez/" onClick={onClose}>
          <div>Adhérez</div>
        </NavLink>
        <NavLink to="/search/" onClick={onClose}>
          <div>Recherche</div>
        </NavLink>
      </Nav>
      <Social>
        <SocialButton
          href={`https://www.facebook.com/${data.facebook}`}
          title="Facebook"
          target="_blank"
          rel="noopener"
        >
          <Facebook />
        </SocialButton>
        <SocialButton
          href={`https://www.instagram.com/${data.instagram}/`}
          title="Instagram"
          target="_blank"
          rel="noopener"
        >
          <Instagram />
        </SocialButton>
      </Social>
    </Container>
  )
}

export default Menu
