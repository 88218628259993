import {BikiniEventTag} from '../types/bikini'

import React from 'react'
import styled from 'styled-components'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'

const Container = styled.span`
  margin-right: 15px;
  min-width: 12px;
  display: inline-block;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 14px;
  }
`

const Chip = styled.span<{tag: BikiniEventTag}>`
  display: inline-block;
  border-radius: 10px;
  background: ${(props) => colors.tags[props.tag]};
  margin-right: -8px;
  width: 15px;
  height: 15px;
  vertical-align: -2px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 11px;
    height: 11px;
    margin-right: -5px;
    vertical-align: 0;
  }
`

interface Props {
  tags: BikiniEventTag[]
}

const Tags: React.FC<Props> = ({tags}) => (
  <Container>
    {tags
      .filter((tag: BikiniEventTag) => colors.tags[tag]) // only keep tags with colors
      .map((tag: BikiniEventTag) => (
        <Chip key={tag} tag={tag} />
      ))}
  </Container>
)

export default Tags
