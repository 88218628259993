import {BikiniEvent, AlgoliaRecord} from '../types/bikini'

import {format, differenceInCalendarDays} from 'date-fns'
import {utcToZonedTime} from 'date-fns-tz'
import {fr} from 'date-fns/locale'
import {ucfirst} from './text'

const localeFormat = (date: Date | number, pattern: string): string => {
  try {
    date = utcToZonedTime(date, 'Europe/Paris')
  } catch (_) {
    // stay with local timezone in older browsers
  }
  return format(date, pattern, {locale: fr})
}

const TODAY = new Date()

export const dayMonthYear = (start: number, end?: number, full?: boolean): string => {
  // for events
  if (end) {
    let startPattern = 'EEEE d'
    let endPattern = 'EEEE d'
    if (full) {
      endPattern += ' MMMM yyyy'
      const startDate = new Date(start)
      const endDate = new Date(end)
      if (startDate.getMonth() !== endDate.getMonth()) {
        startPattern += ' MMMM'
      }
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        startPattern += ' yyyy'
      }
    }
    return `Du ${localeFormat(start * 1000, startPattern)} au ${localeFormat(end * 1000, endPattern)}`
  }
  return ucfirst(localeFormat(start * 1000, 'EEEE d MMMM yyyy'))
}
export const dayMonth = (start: number, end?: number): string => {
  // for <Upcoming>
  if (end) {
    return `Du ${localeFormat(start * 1000, 'EEEE d')} au ${localeFormat(end * 1000, 'EEEE d MMMM')}`
  }
  return ucfirst(localeFormat(start * 1000, 'EEEE d MMMM'))
}
export const dayMonthShort = (start: number, end?: number): string => {
  // for <EventLine>
  const date = new Date(start * 1000)
  if (end) {
    return `${localeFormat(start * 1000, 'd MMM')} – ${localeFormat(end * 1000, 'd MMM')}`
  }
  return ucfirst(localeFormat(date, date.getFullYear() < TODAY.getFullYear() ? 'E d MMM yyyy' : 'E d MMM'))
}

export const ddmmyy = (start: number): string => localeFormat(start * 1000, 'dd.MM.yy') // for the news

export const yymm = (start: number): string => localeFormat(start * 1000, 'yy-MM') // for list separators
export const month = (start: number): string => ucfirst(localeFormat(start * 1000, 'MMMM')) // for list separators

export const iso8601 = (date: number): string => new Date(date * 1000).toISOString() // for structured data

export const upcoming = (start: number, end?: number): string => {
  // for header and twitter
  const date = new Date(start * 1000)
  const now = new Date()
  const diff = differenceInCalendarDays(date, now)
  if (diff < 0) {
    if (end > currentTimestamp()) {
      return 'actuellement'
    }
    return 'terminé'
  }
  if (diff === 0) {
    if (date.getUTCHours() < 17) {
      return "aujourd'hui"
    }
    return 'ce soir'
  }
  if (diff === 1) {
    return 'demain'
  }
  if (diff < 8) {
    return localeFormat(date, 'EEEE')
  }
  if (diff < 30 && date.getMonth() === now.getMonth()) {
    return localeFormat(date, 'EEEE d')
  }
  return 'le ' + localeFormat(date, 'd MMMM')
}

export const timeLabel = (tags: string[]): string =>
  tags.includes('bikini') ? 'Ouverture des portes : ' : 'Concert : '

export const timeFormat = (start: number, pad?: boolean): string => {
  const date = new Date(start * 1000)
  if (date.getMinutes() || pad) {
    return localeFormat(date, "HH'h'mm")
  } else {
    return localeFormat(date, 'HH') + 'h'
  }
}

export const EVENT_DURATION = 6 * 3600 // 6 hours

export const currentTimestamp = () => Math.round(Date.now() / 1000)

export const ended = ({end, date}: BikiniEvent | AlgoliaRecord) => (end || date + EVENT_DURATION) < currentTimestamp()

export const eventFilter = ({end, date, unveilDate}: BikiniEvent | AlgoliaRecord): boolean => {
  const now = currentTimestamp()
  return (end || date + EVENT_DURATION) > now && unveilDate < now
}
