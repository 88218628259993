import React, {SVGAttributes} from 'react'

const Menu: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
)

export default Menu
