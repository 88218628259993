import React, {useRef, useState, useEffect, MouseEvent} from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'

import Facebook from '../images/facebook'
import Instagram from '../images/instagram'
import Tagline from '../images/tagline'
import ROCK from '../images/rock.svg'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {socialButton} from '../helpers/style'

const Container = styled.footer`
  background: ${colors.primary};
  text-align: center;
  color: ${colors.white};
  padding-top: 40px;
  margin-top: 25px;
`

const NewsletterTitle = styled.div`
  margin: 30px;
  font-size: 1.1rem;
  font-weight: 600;
`
const NewsletterForm = styled.form`
  padding: 0 10px;
  * {
    vertical-align: middle;
    height: 46px;
  }
`
const Input = styled.input`
  border: none;
  background: ${colors.secondary};
  padding: 0 20px;
  width: 260px;
  color: ${colors.white};
  ::placeholder {
    color: ${colors.white};
  }
  @media (max-width: 500px) {
    width: calc(100% - 40px); /* remove padding */
  }
`
const FormButton = styled.button`
  background: ${colors.white};
  color: ${colors.primary};
  font-weight: bold;
  width: 135px;
  @media (max-width: 500px) {
    width: 100%;
  }
  :disabled {
    color: ${colors.grey};
  }
`

const Social = styled.div`
  margin: 16px;
`
const SocialButton = styled.a`
  ${socialButton(colors.white)}
`

const Nav = styled.div`
  margin: 45px 0;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`
const NavLink = styled(Link)`
  display: inline-block;
  color: ${colors.white};
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px;
`

const Legal = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin: 55px;
  padding: 10px;
  a {
    color: ${colors.white};
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 35px auto;
  }
`
const TaglineContainer = styled.div`
  width: 697px;
  max-width: 100%;
  margin: 0 auto;
  cursor: help;
  svg {
    width: 100%;
    display: block; /* https://stackoverflow.com/a/6540225/1052033 */
  }
`

const Rock = styled.div`
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 70px;
  bottom: 90px;
  img {
    height: 80%;
    max-width: 90%;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 50px;
    bottom: 50px;
  }
`

const YEAR = new Date().getFullYear()

const Footer: React.FC = () => {
  const {
    site: {siteMetadata: data},
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          facebook
          instagram
        }
      }
    }
  `)

  const [submitting, setSubmitting] = useState(false)

  const [showRock, toggleRock] = useState(false)
  const wait = useRef(false)

  const handleSubmit = () => {
    setSubmitting(true)
  }

  const done = () => {
    wait.current = false
  }

  const handleHover = (event: MouseEvent) => {
    event.stopPropagation()
    toggleRock(true)
    wait.current = true
    setTimeout(done, 100)
  }

  const handleClick = () => {
    toggleRock(true)
  }

  const out = () => {
    if (!wait.current) {
      toggleRock(false)
    }
  }

  const handleOut = (event: MouseEvent) => {
    event.stopPropagation()
    setTimeout(out, 100)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (showRock) {
        toggleRock(false)
      }
    }

    window.addEventListener('scroll', handleScroll, {passive: true})
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showRock])

  return (
    <Container>
      <NewsletterTitle>Inscription newsletter</NewsletterTitle>
      <NewsletterForm action={process.env.GATSBY_MAILCHIMP} method="post" onSubmit={handleSubmit}>
        <Input type="email" name="EMAIL" placeholder="Adresse e-mail" aria-label="E-mail" />
        <FormButton type="submit" disabled={submitting}>
          {submitting ? 'Patientez...' : 'Envoyer'}
        </FormButton>
      </NewsletterForm>
      <Social>
        <SocialButton
          href={`https://www.facebook.com/${data.facebook}`}
          target="_blank"
          rel="noopener"
          title="Facebook"
        >
          <Facebook />
        </SocialButton>
        <SocialButton
          href={`https://www.instagram.com/${data.instagram}/`}
          target="_blank"
          rel="noopener"
          title="Instagram"
        >
          <Instagram />
        </SocialButton>
      </Social>
      <Nav>
        <NavLink to="/programmation/">Programmation</NavLink>
        <NavLink to="/billetterie/">Billetterie</NavLink>
        <NavLink to="/infos/">Infos pratiques</NavLink>
        <NavLink to="/adherez/">Adhérez</NavLink>
      </Nav>
      <Legal>
        © {YEAR} {data.title}. Tous droits réservés. <Link to="/legal/">Mentions légales</Link>
      </Legal>
      <TaglineContainer onClick={handleClick} onMouseOver={handleHover} onMouseOut={handleOut}>
        <Tagline pointerEvents="none" />
      </TaglineContainer>
      {showRock && (
        <Rock onClick={handleOut}>
          <img src={ROCK} alt="" />
        </Rock>
      )}
    </Container>
  )
}

export default Footer
