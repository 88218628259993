import {css} from 'styled-components'

import {colors} from './constants'

export const ellipsis = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

// https://css-tricks.com/line-clampin/
// https://www.caniuse.com/#feat=css-line-clamp
// Use JS ellipsis() for non-webkit as a fallback
export const clamp = (lines: number) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
`

export const button = css`
  background: ${colors.primary};
  color: ${colors.white};
  border-radius: 20px;
  padding: 10px 20px;
`

export const socialButton = (color: string = colors.primary) => css`
  display: inline-block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  line-height: 0;
  padding: 8px;
  margin: 16px;
  border-radius: 20px;
  border: 1px solid ${colors.grey};
  transition: border 0.2s;
  :hover {
    border-color: ${color};
  }
  svg {
    width: 16px;
    height: 16px;
    fill: ${colors.grey};
    transition: fill 0.2s;
  }
  :hover svg {
    fill: ${color};
  }
`
