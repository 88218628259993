import React from 'react'
import styled from 'styled-components'

import SEO, {Props as SEOProps} from './seo'
import Header from './header'
import Footer from './footer'

import {MOBILE_BREAKPOINT} from '../helpers/constants'

const Content = styled.main`
  margin: 0 auto;
  max-width: 1000px;
  min-height: 60vh;
  padding: 25px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px;
  }
`

const Layout: React.FC<SEOProps> = ({children, ...props}) => (
  <>
    <SEO {...props} />
    <Header />
    <Content>{children}</Content>
    <Footer />
  </>
)

export default Layout
