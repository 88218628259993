import {BikiniEvent, BikiniSection, AlgoliaRecord} from '../types/bikini'

export const ellipsis = (text: string, max: number): string => {
  if (!text) {
    return ''
  }
  if (text.length <= max) {
    return text
  }
  return text.substr(0, max - 1) + '…'
}

export const ucfirst = (text: string): string => {
  if (!text) {
    return ''
  }
  return text.substr(0, 1).toUpperCase() + text.substr(1)
}

export const available = ({status}: BikiniEvent | AlgoliaRecord): boolean => {
  return status !== 'full' && status !== 'cancelled' && status !== 'postponed'
}

export const getSubtitle = (event: BikiniEvent): string => {
  const infos = []
  if (event.style) {
    infos.push(event.style)
  }
  if (event.place) {
    infos.push(event.place.name)
  }
  return infos.join(', ')
}

export const moneyFormat = (amount: number): string => {
  const str = amount % 1 === 0 ? String(amount) : amount.toFixed(2).replace('.', ',')
  return str + ' €'
}

export const getQuery = (str: string): object => {
  const pairs = str.split('&')
  const obj = {}
  pairs.forEach((pair) => {
    const sides = pair.split('=')
    obj[sides[0]] = decodeURIComponent(sides[1])
  })
  return obj
}

export const queryMatches = (str: string, params: object): boolean => {
  const query = getQuery(str)
  for (const key in params) {
    if (String(params[key]) !== query[key]) {
      return false
    }
  }
  return true
}

export const contentImages = ({node: section}: {node: BikiniSection}): BikiniSection => {
  section.childrenFile.forEach(({url, childImageSharp}) => {
    section.content = section.content.replace(url, childImageSharp.fluid.src)
  })
  return section
}

export const getStartPrice = ({startPrice, free}: BikiniEvent | AlgoliaRecord): string => {
  if (free) {
    return 'Gratuit'
  }
  if (startPrice) {
    return `À partir de ${moneyFormat(startPrice)}`
  }
  return ''
}
