import {BikiniEvent, AlgoliaRecord} from '../types/bikini'

import React from 'react'

import {currentTimestamp, ended} from '../helpers/date'

const getTicketLink = (event: BikiniEvent | AlgoliaRecord): string => {
  if (event.ticketUrl && event.status !== 'cancelled' && event.status !== 'full' && !ended(event)) {
    return event.ticketUrl
  }
  return null
}

const getActionLabel = (event: BikiniEvent | AlgoliaRecord) => {
  const {status, free} = event
  if (status === 'postponed') {
    return 'Reporté'
  }
  if (status === 'cancelled') {
    return 'Annulé'
  }
  if (status === 'full') {
    return 'Complet'
  }
  if (ended(event)) {
    return 'Terminé'
  }
  if (status === 'almost') {
    return 'Dernières places'
  }
  if (free) {
    return 'Gratuit'
  }
  return 'Réserver'
}

const getAnswer = (message: string) => {
  const answer = prompt(message)
  return answer && answer.trim()
}

interface Props {
  event: BikiniEvent | AlgoliaRecord
  className?: string
}

const BookButton: React.FC<Props> = ({event, className}) => {
  const password = event.passwordLimitDate > currentTimestamp() ? event.password : null
  const ticketLink = getTicketLink(event)

  let handleClick: (clickEvent: React.MouseEvent<HTMLAnchorElement>) => void
  let href: string

  if (ticketLink) {
    if (password && event.passwordLimitDate > currentTimestamp()) {
      href = event.path
      handleClick = (clickEvent) => {
        const answer = getAnswer(event.passwordPrompt || 'Mot de passe :')
        if (answer === password) {
          clickEvent.currentTarget.setAttribute('href', ticketLink)
          // let the event bubble to go to the new href
          return
        }
        // wrong answer
        if (answer) {
          // non-empty answer
          alert('Mot de passe incorrect')
        }
        // wrong answer or no answer => cancel the action
        clickEvent.preventDefault()
      }
    } else {
      href = ticketLink
    }
  }

  return (
    <a onClick={handleClick} href={href} className={className}>
      {getActionLabel(event)}
    </a>
  )
}

export default BookButton
