import {ImageNode} from '../types/bikini'

import React from 'react'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

export interface Props {
  title?: string
  noindex?: boolean
  description?: string
  url?: string
  image?: ImageNode
}

const SEO: React.FC<Props> = ({title, noindex, description, url, image}) => {
  const {
    site: {siteMetadata: data},
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          locale
          twitter
          keywords
          hostname
        }
      }
    }
  `)

  const pageTitle = title ? `${title} | ${data.title}` : data.title // `${data.title} | ${data.description}`

  const meta = [
    {
      property: `og:title`,
      content: pageTitle, // TODO: maybe not include data.title
    },
    {
      name: `description`,
      property: `og:description`,
      content: description || data.description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:site`,
      content: `@${data.twitter}`,
    },
    {
      name: 'keywords',
      content: data.keywords.join(', '),
    },
    {
      property: `og:locale`,
      content: data.locale,
    },
    {
      property: `og:site_name`,
      content: data.title,
    },
  ]

  if (image) {
    if (image.childImageSharp) {
      const resized = image.childImageSharp.fluid || image.childImageSharp.fixed
      meta.push({
        property: `og:image`,
        content: `https://${data.hostname}${resized.src}`,
      })
      meta.push({
        property: `og:image:width`,
        content: resized.presentationWidth,
      })
      meta.push({
        property: `og:image:height`,
        content: resized.presentationHeight,
      })
    } else {
      // old events:
      meta.push({
        property: `og:image`,
        content: `https://${data.hostname}${image.publicURL}`,
      })
    }
  }
  if (url) {
    meta.push({
      property: `og:url`,
      content: url,
    })
  }

  if (noindex) {
    meta.push({
      name: `robots`,
      content: `noindex`,
    })
  }

  const htmlAttr = {
    lang: data.locale.substr(0, 2),
  }

  return (
    <Helmet htmlAttributes={htmlAttr} meta={meta}>
      <title>{pageTitle}</title>
      <link rel="preconnect" href="//cloud.typenetwork.com" />
    </Helmet>
  )
}

export default SEO
