import React from 'react'
import Img, {GatsbyImageWithIEPolyfillProps} from 'gatsby-image/withIEPolyfill' // tslint:disable-line no-submodule-imports

import {ImageNode} from '../types/bikini'

interface Props {
  src?: ImageNode | string
}

const Image: React.FC<Props & GatsbyImageWithIEPolyfillProps> = ({src, ...props}) => {
  if (!src) {
    return null
  }
  const {childImageSharp} = src as ImageNode
  if (childImageSharp) {
    return <Img fluid={childImageSharp.fluid} fixed={childImageSharp.fixed} {...props} />
  }
  return (
    <img
      src={(src as ImageNode).publicURL || (src as string)}
      className={props.className as string}
      style={props.style}
      alt={props.alt}
    />
  )
}

export default Image
