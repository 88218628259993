import React, {CSSProperties} from 'react'
import {Link as GatsbyLink} from 'gatsby'

interface UniversalProps {
  url: string
  className?: string
  style?: CSSProperties
  target?: '_blank'
  title?: string
}

const Link: React.FC<UniversalProps> = ({url, children, ...props}) => {
  if (url && url.startsWith('/')) {
    return (
      <GatsbyLink to={url} {...props}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={url || null} rel="noopener" {...props}>
      {children}
    </a>
  )
}

export default Link
